import React from 'react';
import PropTypes from 'prop-types'

const CustomTable = ({ data, color }) => {
  const keys = Object.keys(data).filter(key => key !== 'id' && data[key]);
  
  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'boolean') {
        data[key] = data[key] ? 'Sì' : 'No';
    }
  });

  console.log(keys);

  return (
    <div className="flex flex-col border border-stone-200 rounded-lg h-min self-center">
      {keys.map((key, index) => (
        <div
          key={key}
          className={`flex item-center font-semibold ${index % 2 === 1 ? '' : ' shadow-md text-black'}`}
        >
          <div className={
            `flex-1 p-2 text-right uppercase
            ${index % 2 === 1 ? 'backdrop-blur-sm border-r' : color + ' font-semibold bg-opacity-30 '}
            ${index === 0 ? 'rounded-tl-lg' : ""}
            ${index === keys.length - 1 ? 'rounded-bl-lg' : ""}`
          }>
            {key}
          </div>
          <div className={`flex-1 p-2 ${index % 2 === 1 ? 'backdrop-blur-sm' : color + ' font-semibold bg-opacity-30 text-black'}`}>
            {data[key].toString()}
          </div>
        </div>
      ))}
    </div>
  );
};

CustomTable.propTypes = {
    data: PropTypes.Object,
    color: PropTypes.String
  }

export default CustomTable;
