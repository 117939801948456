import React from 'react';
import PropTypes from 'prop-types'

const GridElement = ({ children, name, description, color }) => {

  //   const colorDict = {
  //     'orange': 'shadow-orange-300',
  //     'red': 'shadow-red-500',
  //     'yellow': 'shadow-yellow-200',
  //     'blue': 'shadow-blue-900',
  //   }

  const colorBorder = {
    'orange': 'border-orange-300',
    'red': 'border-red-500',
    'yellow': 'border-yellow-200',
    'blue': 'border-blue-900'
  }

  return (
    <div className={'border border-stone-200 rounded-lg p-4 pt-4 mb-7 bg-stone-100 bg-opacity-10 shadow-lg backdrop-blur-xs shadow-[inset_0px_0px_50px_0px_rgba(0,0,0,0.4)] sm:p-4'}>
      <h3 className='text-5xl font-medium text-center mb-0'>{name}</h3>
      <div className={'my-4 sm:mt-8 sm:bm-8 text-sm text-left flex flex-column align-items-center justify-items-center'}>
        <div className={'pl-2 width-fit border-solid border-s-4 text-base ' + colorBorder[color]} >
          {description}
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-5 sm:gap-10">
        {children}
      </div>
    </div>
  );
};

GridElement.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
}

export default GridElement;