import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'

const Carousel = ({ imgs }) => {
    const [current, setCurrent] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const count = imgs.length

    useEffect(( ) => {
        console.log('Component initialized');
        
        const initialIdTimer = startTimer(5000)// eslint-disable-line 
        return () => {
            console.log("unmounting and clearing ", initialIdTimer);
            clearInterval(initialIdTimer); // eslint-disable-line 
        };
      }, []); // eslint-disable-line 

    function nextPage() {
        clearInterval(intervalId); // Clear any existing interval
        setCurrent((current + 1) % count)
      
        const newIntervalId = setInterval(() => {
            setCurrent((current) => (current + 1) % count);
        }, 6000); 

        setIntervalId(newIntervalId)
    }

    function previousPage() {
        clearInterval(intervalId); // Clear any existing interval
        if (current == 0){
            setCurrent((count - 1));
            return;
        }
        setCurrent((current - 1) % count)
      
        const newIntervalId = setInterval(
            () => {
                setCurrent((current) => (current + 1) % count);
            }, 
            6000
            ); 

        setIntervalId(newIntervalId)
    }


    function startTimer(time) {
        if (intervalId) {
            clearInterval(intervalId); // Clear any existing interval
        }
      
        const newIntervalId = setInterval(() => {
            setCurrent((current) => (current + 1) % count);
        }, time);

        console.log("newIntervalId", newIntervalId);
      
        setIntervalId(newIntervalId)
        return newIntervalId
      }

    return (

        <div className="overflow-hidden relative w-100 h-100">
            
            <div 
                className="whitespace-nowrap transition ease-out duration-700 h-100"
                style = {{
                    transform: `translateX(-${current * 100}%)`,
                }}
            >
                {
                    imgs.map((key, index) => (            
                        <div 
                            key={index}
                            className='inline-block align-center m-0-auto w-full h-100 relative overflow-hidden'
                        >
                            <div className='flex h-100 w-full relative place-content-center'>
                                <img 
                                    className='m-auto align-middle'
                                    key={"carousel" + index} 
                                    src={process.env.PUBLIC_URL + key} 
                                    alt="...">
                                </img>
                            </div>
                        </div>
                    ))
                }



            </div>
      

            {/* next and prev */}
            <button 
                type="button" 
                className="absolute top-0 start-0 z-30 flex items-center h-full w-1/2 px-4 cursor-pointer group focus:outline-none" 
                onClick={previousPage}
            >
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/80 dark:bg-black/80 group-focus:outline-none border-white/80 border">
                    <svg className="w-4 h-4 text-black dark:text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                    </svg>
                    
                    <span className="sr-only">Previous</span>
                </span>
            </button>
            <div type="button" className="absolute top-0 end-0 z-30 flex items-center justify-end h-full w-1/2 px-4 cursor-pointer group focus:outline-none" 
                onClick={nextPage}
            >
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/80 dark:bg-black/80 group-focus:outline-none place-content-end border-white/80 border">
                    <svg className="w-4 h-4 text-black dark:text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                    </svg>
                    <span className="sr-only">Next</span>
                </span>
            </div>
        </div>

    );
};

Carousel.propTypes = {
    imgs: PropTypes.array
  }

export default Carousel;
