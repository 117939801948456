import React from 'react';

import './App.css';
// import '@coreui/coreui/dist/css/coreui.min.css'

import GridElement from './components/GridElement';
import Footer from './components/Footer';
import CustomTable from './components/CustomTable';
import Carousel from './components/Carousel';


function App() {

  const chimneyDetails = [
    {
      id: 1,
      MATERIALE: 'AISI 304',
      SPESSORE: '10/10',
      ISPEZIONABILE: true,
      FINITURA: 'INOX NATURALE O TABELLA RAL',
      APRIBILE: true,
      RETE: 'SU RICHIESTA MAGLIA 15X15',
      MISURE: '',
    },
    {
      id: 2,
      MATERIALE: 'AISI 304',
      SPESSORE: '10/10',
      ISPEZIONABILE: true,
      FINITURA: 'INOX NATURALE O TABELLA RAL',
      APRIBILE: true,
      RETE: 'SU RICHIESTA MAGLIA 15X15',
      MISURE: '',
    },
    {
      id: 3,
      MATERIALE: 'AISI 304',
      SPESSORE: '8/10',
      ISPEZIONABILE: true,
      FINITURA: 'INOX NATURALE O TABELLA RAL',
      APRIBILE: false,
      RETE: 'No',
      MISURE: 'D165 X H450 8/10',
      "MISURE PIOMBO": '700X600 10/10',
    },
    {
      id: 3,
      MATERIALE: 'AISI 304',
      SPESSORE: '8/10',
      ISPEZIONABILE: true,
      FINITURA: 'INOX NATURALE O TABELLA RAL',
      APRIBILE: false,
      RETE: 'No',
      MISURE: 'H250 E H400',
    },
  ];

  return (
    <div className="bg-slate-50 bg-[url('./bg5.png')] bg-repeat bg-fixed">

      <div className="p-3 sm:p-5 font-grotesque max-w-6xl m-auto" >

        <header className="App-header">
          <h1 className="text-center text-6xl sm:text-8xl font-black text-black font-titlegrotesque ">
            Universal Inox</h1>

          <div className='text-center my-4 sm:mt-1'>
            <sub className='text-sm'>
              Il comignolo di un altro pianeta
            </sub>
          </div>

          <div className='justify-center self-center'>
            <img
              src={process.env.PUBLIC_URL + "/assets/schiera1.png"}
              alt="chimney"
              // style={imageStyle}
              className={" self-center "}
            />
          </div>

          {/* <div className='flex justify-center self-center'>
            <img
              src={process.env.PUBLIC_URL + "/assets/5.png"}
              alt="chimney"
              // style={imageStyle}
              className="w-44 self-center rotate-180 my-5 translating-image sm:hidden"
            />
          </div> */}

          <div className='p-4 sm:mt-0 sm:pt-0'>
            <sub className='text-lg text-justify'>
              Universal INOX S.R.L. è una piccola azienda con sede ad Anzano (TV) che pur trovandosi sul pianeta terra si distingue nella produzione di <b>comignoli</b> di un altro pianeta. Offriamo <b>soluzioni personalizzate</b>, garantendo che ogni comignolo sia un pezzo unico e funzionale, pronto a soddisfare le diverse necessità architettoniche e stilistiche dei nostri clienti. La nostra missione è rendere spaziale ogni tetto, offrendo prodotti di qualità e servizi su misura.
            </sub>
          </div>

        </ header>

        <h2 className="text-4xl sm:text-6xl mt-6 sm:mt-8 mb-2 font-bold text-center">Catalogo Comignoli</h2>

        <div className='my-2 text-center'>
          <div className='pl-4'>
            <sub className='text-sm'>
              Esplora il nostro catalogo, contattaci per un preventivo.
            </sub>
          </div>
        </div>

        <GridElement
          name={"Saturno"}
          description={"Comignolo saldato, disponibile nelle versioni: 3/4/5 alette con coperchio"}
          color={'orange'}>
          <CustomTable data={chimneyDetails[0]} color={"bg-orange-300"}></CustomTable>

          <Carousel imgs={["/assets/1.png", "/assets/2.png"]}></Carousel>

        </GridElement>

        <GridElement
          name={"Marte"}
          description={"Comignolo saldato"}
          color={'red'}>
          <CustomTable data={chimneyDetails[1]} color={"bg-red-300"}></CustomTable>
          <Carousel imgs={["/assets/marte1.png", "/assets/marte2.png", "/assets/marte3.png", "/assets/marte4.png"]}></Carousel>
        </GridElement>

        <GridElement
          name={"Star"}
          description={"Esalatore con conversa in piombo, disponibile nelle versioni: cappello piccolo o grande, pendenza 22%"}
          color={'yellow'}>
          <CustomTable data={chimneyDetails[2]} color={"bg-yellow-300"}></CustomTable>
          <Carousel imgs={["/assets/star1.png", "/assets/star2.png", "/assets/star3.png", "/assets/star4.png"]}></Carousel>
        </GridElement>

        <GridElement
          name={"Plutone"}
          description={"Esalatore disponibile in tutti I diametri standard e in duealtezze"}
          color={'blue'}>
          <CustomTable data={chimneyDetails[3]} color={"bg-blue-300"}></CustomTable>

          <Carousel imgs={["/assets/plutone.png", "/assets/plutone2.png"]}></Carousel>

        </GridElement>

        <div className='table sm:hidden justify-center self-center my-5'>
          <img
            src={process.env.PUBLIC_URL + "/assets/schiera1.png"}
            alt="chimney"
            // style={imageStyle}
            className={" self-center "}
          />
        </div>

        <Footer></Footer>

        <div className='mt-5 flex justify-center'>
          <img
            src={process.env.PUBLIC_URL + 'logo.ico'}
            alt="chimney"
            // style={imageStyle}
            className={"w-32 self-center"}
          />
        </div>

      </div>
    </div>
  );
}

export default App;
