import React from 'react';

const Footer = () => {
  return (
    <footer className="text-center shadow-sm border border-stone-400 rounded-lg p-5 pt-2 mb-7 bg-stone-100 bg-opacity-10 shadow-lg backdrop-blur-xs shadow-[inset_0px_0px_50px_0px_rgba(0,0,0,0.4)]">
        <h3 className='text-3xl font-bold mb-2'>Contatti</h3>
        <div className="flex text-left space-x-4 flex-col">

            <div className='!m-0'>
                <p><span className="font-bold">Email:</span> commercialeuniversal@gmail.com</p>
            </div>
            
            <div className='!m-0'>
                <p><span className="font-bold">Telefono:</span> TBD</p>
            </div>

            <div className='!m-0'>
                <p><span className="font-bold">P.IVA:</span> 05452390262</p>
            </div>

            <div className='!m-0'>
                <p><span className="font-bold">Address:</span> ANZANO DI CAPPELLA MAGGIORE (TV) VIA DEI PAOLIN, 9 (31012)</p>
            </div>

        </div>
    </footer>
  );
};

export default Footer;
